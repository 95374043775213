@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  min-height: 100vh;
  width: 100vw;
  min-width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  max-width: 2780px !important;
} */

*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

input, button, textarea, select {
  font: inherit;
}

body {
  max-width: 2780px !important;
  margin: auto;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

@layer base {
  :root {
    --radius: 0.5rem;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 70%;
  }
}

#__next {
  isolation: isolate;
}

.no-scroll {
  overflow: hidden !important;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
  z-index: 49; /* Ensure the backdrop is above other content */
}

.transition-element {
  view-transition-name: dcs;
}

::view-transition-old(dcs) {
  animation: fade-out 0.8s ease-in-out;
}

::view-transition-new(dcs) {
  animation: fade-in-up 0.4s ease-in-out;
}

@keyframes fade-out {
  0% {
    transform: translateY(0) translateX(-0.2px);
    opacity: 1;
    font-weight: 500;
  }

  20% {
    transform: translateY(24px) translateX(-0.2px);
    opacity: 0.5;
  }

  40% {
    transform: translateY(24px) translateX(-0.2px);
    opacity: 0.3;
  }

  60% {
    transform: translateY(24px) translateX(-0.2px);
    opacity: 0.2;
  }

  100% {
    transform: translateY(24px) translateX(-0.2px);
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  60% {
    opacity: 0.8;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-up {
  0% {
    transform: translateY(24px) translateX(-0.2px);
    opacity: 0;
  }

  20% {
    transform: translateY(24px) translateX(-0.2px);
    opacity: 0.5;
  }

  80% {
    transform: translateY(0) translateX(-0.2px);
    opacity: 0.7;
  }

  100% {
    transform: translateY(0) translateX(-0.2px);
    opacity: 1;
  }
}
